var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('master-page',{attrs:{"icone":"mdi-domain","titulo":"Empresa","subTitulo":"Lista de Empresas para uso no sistema"},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.AbrirDialogCadastro()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Novo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.Atualizar()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])]),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.ExportarExcel('tabela')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel-outline")])],1),_c('v-list-item-title',[_vm._v("Exportar (Excel)")])],1)],1)],1)]},proxy:true}])},[_c('text-search',{attrs:{"search":_vm.search},on:{"update:search":function($event){_vm.search=$event},"mudou":function($event){return _vm.Atualizar()}}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tabela","headers":_vm.headers,"items":_vm.lista,"options":_vm.options,"server-items-length":_vm.totalLista,"loading":_vm.loading,"footer-props":{ showFirstLastPage: true}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.AbrirDialogCadastro(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.Excluir(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}],null,true)}),_c('cadastro-empresa',{attrs:{"item":_vm.item},on:{"fechou":function($event){_vm.dialogCadastro = false},"salvou":function($event){return _vm.Atualizar()}},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }